import Box from "./Box";
import Input from "./form/Input";
import React from "react";
import Button from "./form/Button";
import TableUi from "./table/Table";
import { Streams } from "./form/Streams";
import ButtonWarning from "./form/ButtonWarning";
import EventDisplay from "./EventDisplay";


class Form extends React.Component {
  host = window.location.hostname;
  getHeadings = (streamName) => {
    for(let i=0; i < this.state.schemaToFilterValues.length; i++) {
      if(this.state.schemaToFilterValues[i]['streamName'] === streamName) {
        return this.state.schemaToFilterValues[i]['fields'];
      }
    }
  }

  constructor(props) {

    super(props);
    this.state = {
      count: 0,
      userIdentifier: "",
      userIdentifierSelected: "",
      schema: [],
      schemaSelected: [],
      schemaToFilter: [],
      schemaToFilterValues: [],
      dataArray: [],
      streams: null,
      interval: null,
      hasResult: false
    }

    this.stopMonitor = this.stopMonitor.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onSchemaChange = this.onSchemaChange.bind(this);
    this.submitForm = this.submitForm.bind(this)
  }

  onTextChange(event) {
    this.setState({ userIdentifier: event.target.value })
  }


  onSchemaChange(event) {
    let schemaSelected = Array.from(event.target.selectedOptions, option => option.value);

    this.setState({
      schemaSelected: schemaSelected
    })

  }

  stopMonitor() {
    this.setState({ interval: null })
    clearInterval(this.state.interval)
  }

  submitForm(event) {
    event.preventDefault();
    this.state.userIdentifierSelected = this.state.userIdentifier;
    this.state.schemaToFilter = [];
    this.state.schemaToFilterValues = [];
    for(let i=0; i< this.state.schemaSelected.length ; i++){
      const json = JSON.parse(this.state.schemaSelected[i])
      this.state.schemaToFilterValues.push(json)
      this.state.schemaToFilter.push(json['streamName'])
    }

    this.setState({ interval: null })
    clearInterval(this.state.interval)
    this.setState({ hasResult: false })
    if (!(this.state.schemaSelected.length > 0) || !this.state.userIdentifier) {
      return
    }

    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ streamNames: this.state.schemaToFilter })
    };
    fetch("event-viewer/v1/filter/" + this.state.userIdentifierSelected, requestOptions);

    this.fetchEvents();
    const intervalFunction = setInterval(() => {
      this.fetchEvents();
    }, 15000)
    this.state.interval = intervalFunction;
    this.setState({ interval: intervalFunction })
  }

  fetchEvents() {
    const schema = encodeURIComponent(this.state.schemaToFilter);
    fetch("event-viewer/v1/events/" + this.state.userIdentifierSelected + "?streams=" + schema)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        if (!data) {
          return;
        }

        this.setState({ dataArray: data })
        for (let i = 0; i < data.length; i++) {
          if (data[i]['events'].length > 0) {
            this.setState({ hasResult: true })
          }
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }

  render() {
    let eventDisplay = null;
    if (this.state.hasResult) {
      let events = {};
      for (let i = 0; i < this.state.dataArray.length; i++) {
        const streamName = this.state.dataArray[i]['streamName'];
        events[streamName] = this.state.dataArray[i]['events'];
      }

      eventDisplay = (
        <EventDisplay events={events} />
      )
    }

    return (
      <>
        <div className="container">
          <div className="notification is-info">

                        The events are monitored in real time, make sure to generate the events after you start monitoring.
          </div>
        </div>
        <Box>

          <div className="field">
            <label className="label">Identifier</label>
            <div className="control">
              <Input text={this.state.userIdentifier} onChange={this.onTextChange} placeholder="web856a.10.144.34.88.187E2E537D5"/>
            </div>
          </div>


          <div className="field">
            <label className="label">Streams</label>
            <div className="control">
              <Streams
                onChange={this.onSchemaChange}/>
            </div>
          </div>
          <nav className="level">
            <div className="level-left"/>
            <div className="level-right">
              <p className="level-item">
                <Button onClick={this.submitForm}>Monitor</Button>
              </p>
              <ButtonWarning onClick={this.stopMonitor}>Stop Monitor</ButtonWarning>
            </div>
          </nav>


        </Box>
        <div>
          {!this.state.hasResult && !this.state.interval}
          <hr/>
          {!this.state.hasResult && this.state.interval && <>
            <div className="section loader is-loading"></div>
            <p>Monitoring the streams, it will take approximately a minute to load the events</p></>}
          {eventDisplay}
        </div>


      </>
    )
  }
}


export default Form;