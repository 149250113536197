import { useState } from "react";
import Button from "../../components/common/elements/Button";
import Icon from "../../components/common/elements/Icon";
import Table from "../../components/common/elements/Table";
import Snippet from "../../components/common/elements/Snippet";
import EventModal from "./EventModal";

import { faClipboardList, faFileCode, faMagnifyingGlass, faCalendar, faClock, faCalendarDay, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

import "../css/EventDisplay.css";

/**
 * Display the event results.
 *
 * -- Props --
 * | Name         | Type        | Required  | Default Value | Description
 * |--------------|-------------|-----------|---------------|---------------
 * | id           | string      | false     | null          | An id to apply to this component.
 * | className    | string      | false     | ""            | Additional classes to apply to this component.
 * | events       | object      | true      | N/A           | An object whose fields are stream names and values are arrays of event json.
 */
export const EventDisplay = (props) => {
  const streams = Object.keys(props.events).sort();
  const initialStream = streams[0] ? streams[0] : "";

  const [activeStream, setActiveStream] = useState(initialStream);
  const [eventPattern, setEventPattern] = useState("");
  const [dateFilter, setDateFilter] = useState("");
  const [timeFilter, setTimeFilter] = useState("");
  const [copyState, setCopyState] = useState({});
  const [activeEvent, setActiveEvent] = useState(null);
  const [dateFilterError, setDateFilterError] = useState("");
  const [timeFilterError, setTimeFilterError] = useState("");

  // Create the list of total active events, removing any possible invalid values (falsy) from the array
  const activeEvents = (props.events[activeStream] ? props.events[activeStream] : []).filter(event => event);

  const parseCurrentDate = (date) => {
    const year = date.getFullYear();
    const month = formatDateTimeDigit(date.getMonth() + 1)
    const day = formatDateTimeDigit(date.getDate());

    return `${year}-${month}-${day}`
  }

  const parseCurrentTime = (date) => {
    const hour = formatDateTimeDigit(date.getHours());
    const minutes = formatDateTimeDigit(date.getMinutes());
    const seconds = formatDateTimeDigit(date.getSeconds());

    return `${hour}:${minutes}:${seconds}`
  }

  const formatDateTimeDigit = (num) => {
    return num.toString().padStart(2, "0");
  }

  const parseDateTime = (date, time) => {
    return Date.parse(`${date}T${time}`);
  }

  const handleDateTimeChange = (newDate, newTime) => {
    if (newDate === "" && newTime === "") {
      setDateFilter("");
      setDateFilterError("");
      setTimeFilter("");
      setTimeFilterError("");
      return;
    }

    const validDate = parseDateTime(newDate, "00:00:00");
    const validTime = parseDateTime("2024-01-01", newTime);

    if (!validDate) {
      setDateFilterError("Invalid date");
    } else {
      setDateFilterError("");
    }

    if (!validTime) {
      setTimeFilterError("Invalid time");
    } else {
      setTimeFilterError("");
    }

    setDateFilter(newDate);
    setTimeFilter(newTime);
  }

  const setDateTimeNow = () => {
    const now = new Date();
    const date = parseCurrentDate(now);
    const time = parseCurrentTime(now);
    handleDateTimeChange(date, time);
  }

  const clearFilter = () => {
    setEventPattern("");
    setDateFilter("");
    setTimeFilter("");
    setDateFilterError("");
    setTimeFilterError("");
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  }

  const buildCopyIcon = (json) => {
    const event = JSON.parse(json);
    const event_id = event.event_id;
    return (
      <Icon icon={faClipboardList}
        className="copy-btn"
        onMouseLeave={() => setCopyState({ ...copyState, [event_id]: false })}
        onClick={() => { copyToClipboard(JSON.stringify(event, null, 2)); setCopyState({ ...copyState, [event_id]: true }); }}
        tooltip={copyState[event_id] ? "Copied!" : null}
      />
    );
  }

  const buildViewIcon = (index, json) => {
    return (
      <Icon icon={faFileCode}
        className="view-btn"
        onClick={() => setActiveEvent({ index: index, json: json })}
      />
    )
  }

  let headers = [
    { id: 0, value: "Index" },
    { id: 1, value: "Payload", className: "payload-header" },
    { id: 2, value: "Copy" },
    { id: 3, value: "View" }
  ];

  const matchingEvents = activeEvents.filter((eventJson) => {
    // Test the event json against the pattern
    let patternMatches = true;
    if (eventPattern) {
      try {
        const regex = new RegExp(eventPattern, "gi");
        patternMatches = regex.test(eventJson);
      } catch {
        patternMatches = false;
      }
    }

    // Test the event timestamp against the time filter
    let dateTimeMatches = true;

    const dateTimeFilter = parseDateTime(dateFilter, timeFilter);
    if (dateTimeFilter) {
      const event = JSON.parse(eventJson);
      const eventTime = parseInt(event.event_timestamp_ms);
      dateTimeMatches = eventTime >= dateTimeFilter;
    }

    return patternMatches && dateTimeMatches;
  });

  const handlePrevClick = () => {
    const newActiveIndex = Math.max(activeEvent.index - 1, 0);
    const newActiveJson = matchingEvents[newActiveIndex];

    setActiveEvent({ index: newActiveIndex, json: newActiveJson })
  }

  const handleNextClick = () => {
    const newActiveIndex = Math.min(activeEvent.index + 1, matchingEvents.length);
    const newActiveJson = matchingEvents[newActiveIndex];

    setActiveEvent({ index: newActiveIndex, json: newActiveJson })
  }

  const eventRows = matchingEvents.map((eventJson, index) => {
    const event = JSON.parse(eventJson);
    const event_snippet = (
      <Snippet text={eventJson}
        pattern={eventPattern}
        maxLength={120}
        key={eventPattern}
      />
    );

    return {
      id: eventJson,
      values: [
        { id: index, value: index },
        { id: event.event_id + eventJson, value: event_snippet, className: "payload-snippet" },
        { id: event.event_id + "copy", value: buildCopyIcon(eventJson) },
        { id: event.event_id + "view", value: buildViewIcon(index, eventJson) }
      ]
    }
  });

  const hiddenEventCount = activeEvents.length - matchingEvents.length;
  let hiddenEventCountText = null;
  if (hiddenEventCount > 0) {
    hiddenEventCountText = (
      <span><strong> ({hiddenEventCount} hidden)</strong></span>
    )
  }

  let eventModal = null;
  if (activeEvent) {
    eventModal = (
      <EventModal className={"is-active"}
        index={activeEvent.index}
        eventJson={activeEvent.json}
        hasPrev={activeEvent.index > 0 && matchingEvents.length > 0}
        onPrev={handlePrevClick}
        hasNext={activeEvent.index < matchingEvents.length - 1}
        onNext={handleNextClick}
        onClose={() => setActiveEvent(null)}
      />
    );
  }

  const tabs = streams.map(stream => {
    const className = (stream === activeStream) ? "is-active" : "";
    return (
      <li key={stream}
        className={className}
        onClick={() => setActiveStream(stream)}
      >
        <a>{stream}</a>
      </li>
    );
  });

  let dateErrorClass = "";
  let dateFilterErrorText = null;
  if (dateFilterError) {
    dateErrorClass = "is-danger";
    dateFilterErrorText = (
      <p className="help is-danger">{dateFilterError}</p>
    );
  }

  let timeErrorClass = "";
  let timeFilterErrorText = null;
  if (timeFilterError) {
    timeErrorClass = "is-danger";
    timeFilterErrorText = (
      <p className="help is-danger">{timeFilterError}</p>
    );
  }

  return (
    <div id={props.id} className={`event-display${props.className ? " " + props.className : ""}`}>
      <div className="tabs">
        <ul>
          {tabs}
        </ul>
      </div>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Search</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control has-icons-left is-expanded">
              <Icon icon={faMagnifyingGlass}/>
              <input className="input" type="text" value={eventPattern} onChange={(event) => setEventPattern(event.target.value)} placeholder="Regex filter"/>
            </p>
          </div>
          <div className="field">
            <p className="control has-icons-left is-expanded">
              <Icon icon={faCalendar}/>
              <input className={"input " + dateErrorClass} type="text" value={dateFilter} onChange={(event) => handleDateTimeChange(event.target.value, timeFilter)} placeholder="Date filter (YYYY-MM-DD)"/>
            </p>
            {dateFilterErrorText}
          </div>
          <div className="field">
            <p className="control has-icons-left is-expanded">
              <Icon icon={faClock}/>
              <input className={"input " + timeErrorClass} type="text" value={timeFilter} onChange={(event) => handleDateTimeChange(dateFilter, event.target.value)} placeholder="Time filter (HH:mm:ss)"/>
            </p>
            {timeFilterErrorText}
          </div>
          <div className="field">
            <p className="control">
              <Button className="is-link" onClick={setDateTimeNow}>
                <Icon icon={faCalendarDay}/>
                <span>Now</span>
              </Button>
            </p>
          </div>
          <div className="field">
            <p className="control">
              <Button className="is-danger" onClick={clearFilter}>
                <Icon icon={faFilterCircleXmark}/>
                <span>Clear Filters</span>
              </Button>
            </p>
          </div>
        </div>
      </div>
      <div className="block">
        <p>Found <strong>{matchingEvents.length}</strong> events{hiddenEventCountText}</p>
      </div>
      <Table className="is-hoverable is-fullwidth"
        headers={headers}
        rows={eventRows}
      />
      <hr/>
      {eventModal}
    </div>
  );
}

export default EventDisplay;
