import { useEffect, useState } from 'react';
import useFetchWithMsal from "../../../useFetchWithMsal";
import MultiSelect from "./MultiSelect";


export const Streams = (props) => {
  const { error, execute } = useFetchWithMsal();
  const [todoListData, setTodoListData] = useState(null);

  useEffect(() => {
    if (!todoListData) {
      execute('GET', 'event-viewer/v1/streams')
        .then((data) => {
          let optionValues = null;
          if(data){
            optionValues= []
            let optionValue = {};
            optionValue.value = "Select"
            optionValue.label = "Select"
            optionValues.push(optionValue)
          }
          for (let key in data) {
            let optionValue = {};
            optionValue.value = JSON.stringify(data[key])
            optionValue.label = data[key]['streamName']
            optionValues.push(optionValue)
          }
          setTodoListData(optionValues);
        });
    }
  }, [execute, todoListData])

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <>{todoListData  ? <MultiSelect options={todoListData}
    onChange={props.onChange}/> : <button className="button is-loading"></button>} </>;
};