export const Input = (props) => {
  return (
    <div className="select is-multiple">
      <select multiple={true}
        onChange={props.onChange}>
        {props.options.map((option, index) => (
          <option value={option.value} key={index}>{option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Input;