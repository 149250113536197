import { AuthenticatedTemplate } from '@azure/msal-react';

import { Login } from '../../Login';
import Header from "../header/Header";
import Form from "./Form";

export const EventViewerComponent = () => {
  return (
    <>
      <Login/>

      <AuthenticatedTemplate>
        <div className="container">
          <Header/>
          <Form/>
        </div>
      </AuthenticatedTemplate>
    </>
  );
}